import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from "primeng/password";
import { InputNumberModule } from 'primeng/inputnumber';
import { AutoFocusModule } from 'primeng/autofocus';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

const PRIMENG_MODULES = [
  ButtonModule,
  InputTextModule,
  PasswordModule,
  InputNumberModule,
  AutoFocusModule,
  KeyFilterModule,
  ToastModule,
  CheckboxModule,
  ProgressBarModule,
  RadioButtonModule,
  DropdownModule,
  ProgressSpinnerModule,
  InputTextareaModule,
  DialogModule,
  DynamicDialogModule
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PRIMENG_MODULES
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    PRIMENG_MODULES
  ]
})
export class SharedModule { }
