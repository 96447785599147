import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { InputNumber, InputNumberInputEvent } from 'primeng/inputnumber';
import { UserService } from 'src/app/services/user.service';
import { maskMobile } from 'src/app/shared/models/utils.model';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent {

  @Input() countdown!: number;

  @Output() verifyCodeEvent = new EventEmitter<string>();
  @Output() requestNewOtpEvent = new EventEmitter<boolean>();

  mobile: string | number;
  phoneCode: string;

  otp1?: number;
  otp2?: number;
  otp3?: number;
  otp4?: number;
  otp5?: number;
  otp6?: number;

  @ViewChildren(InputNumber) otpCodesComponent: QueryList<InputNumber> | undefined;
  otpCodeArray: InputNumber[] | undefined;

  constructor(private userService: UserService) { 
    this.mobile = this.userService.getPhone();
    this.phoneCode = this.userService.getPhoneCode();
  }

  ngAfterViewInit() {
    if (this.otpCodesComponent !== undefined) {
      this.otpCodeArray = this.otpCodesComponent.toArray();
    }
  }

  getMaskMobile() {
    return maskMobile(this.mobile as string, 4);
  }

  onInputOtp(event: InputNumberInputEvent, index: number) {
    if (this.otpCodeArray !== undefined) {
      let validInputList = ['1','2','3','4','5','6','7','8','9','0'];

      let keyboardEvent = event.originalEvent as KeyboardEvent;
      if (validInputList.includes(keyboardEvent.key)) {
        this.otpCodeArray[Math.min(5, index + 1)].input.nativeElement.focus();

        if (this.allowVerifyCode()) {
          this.verifyCodeEvent.emit(this.getOtp());
        }
      }
    }
  }

  onKeyDownOtp(event: KeyboardEvent, index: number) {
    let focusPreviousClass = 'allow-focus-previous';

    if (this.otpCodeArray !== undefined) {
      let currentElement = this.otpCodeArray[index].input.nativeElement;
      let previousElement = this.otpCodeArray[Math.max(0, index - 1)].input.nativeElement;

      if (event.key === 'Backspace') {
        if (currentElement.classList.contains(focusPreviousClass)) {
          previousElement.classList.add(focusPreviousClass);
          previousElement.focus();
          previousElement.value = '';

          currentElement.classList.remove(focusPreviousClass);
        } else {
          currentElement.classList.add(focusPreviousClass);
        }
      } else {
        currentElement.classList.remove(focusPreviousClass);
      }
    }
  }

  allowVerifyCode() {
    if (this.otp1 === null || this.otp1 === undefined) { return false; }
    if (this.otp2 === null || this.otp2 === undefined) { return false; }
    if (this.otp3 === null || this.otp3 === undefined) { return false; }
    if (this.otp4 === null || this.otp4 === undefined) { return false; }
    if (this.otp5 === null || this.otp5 === undefined) { return false; }
    if (this.otp6 === null || this.otp6 === undefined) { return false; }

    return true;
  }

  getOtp() {
    let otp = '';

    otp += this.otp1?.toString();
    otp += this.otp2?.toString();
    otp += this.otp3?.toString();
    otp += this.otp4?.toString();
    otp += this.otp5?.toString();
    otp += this.otp6?.toString();

    return otp;
  }

  requestNewOtp() {
    this.requestNewOtpEvent.emit(true);
  }
}
