import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';
import { RegistrationInfo, RegistrationInfoWorkingObject } from 'src/app/shared/models/api-input.model';
import { Country, PopularCountries } from 'src/app/shared/models/country.model';
import { Gender, IdType, NationalityType, SignUpProgess } from 'src/app/shared/models/enum.model';
import { validateFormIdentificationNumber, validateFormWhitespaceOnly, validateValidCountry } from 'src/app/shared/models/form-validators.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-signup-personal-info',
  templateUrl: './user-signup-personal-info.component.html',
  styleUrls: ['./user-signup-personal-info.component.scss']
})
export class UserSignupPersonalInfoComponent {

  readonly API_URL = environment.TMS_API_URL;
  readonly GENDER = Gender;
  readonly ID_TYPE = IdType;
  readonly NATIONALITY_TYPE = NationalityType;
  readonly COUNTRIES = PopularCountries;
  readonly PROGRESS = SignUpProgess;
  readonly UPLOAD = {
    MYKAD_FRONT: 1,
    MYKAD_BACK: 2,
    PASSPORT: 3,
    PROFILE_PIC: 4
  };

  progress: SignUpProgess;
  info: RegistrationInfoWorkingObject;
  uploadType: number;
  nationalityType: NationalityType | null;
  selectedCountry: Country | null;
  disableContinueIdInfo: boolean;

  @ViewChild('imageUpload') imageUploadRef!: ElementRef;

  // form-related variables
  registerForm: FormGroup;

  // subscription
  uploadImageSub: Subscription | undefined;
  registerSub: Subscription | undefined;

  constructor(private commonService: CommonService, private router: Router, private route: ActivatedRoute, public userService: UserService, private translateService: TranslateService) {
    this.progress = SignUpProgess.SIGNUP_PERSONAL_INFO;
    this.info = new RegistrationInfoWorkingObject();
    this.uploadType = 0;
    this.nationalityType = null;
    this.selectedCountry = null;
    this.disableContinueIdInfo = true;

    this.registerForm = this.initForm();
  }

  initForm() {
    let formGroup = new FormGroup({
      phoneCode: new FormControl(this.info.phoneCode, [Validators.required]),
      phone: new FormControl(this.info.phone, [Validators.required]),
      code: new FormControl(this.info.code, [Validators.required]),
      fullName: new FormControl(this.info.fullName, [
        Validators.required,
        validateFormWhitespaceOnly()
      ]),
      gender: new FormControl(this.info.gender, [Validators.required]),
      email: new FormControl(this.info.email, [
        Validators.required,
        Validators.email,
        validateFormWhitespaceOnly()
      ]),
      nationality: new FormControl(this.info.nationality, [
        Validators.required,
        validateValidCountry(PopularCountries)
      ]),
      idType: new FormControl(this.info.idType, [Validators.required]),
      idNumber: new FormControl(this.info.idNumber, [
        Validators.required,
        validateFormWhitespaceOnly(),
        validateFormIdentificationNumber(this.info.idType)  // refer to setIdType
      ]),
      mykadFront: new FormControl(this.info.mykadFront),
      mykadBack: new FormControl(this.info.mykadBack),
      profilePicture: new FormControl(this.info.profilePicture),
      referralCode: new FormControl(this.info.referralCode)
    });

    formGroup.controls['phoneCode'].setValue(this.userService.getPhoneCode());
    formGroup.controls['phone'].setValue(this.userService.getPhone());
    formGroup.controls['code'].setValue(this.userService.getOtp());

    if (this.userService.getIsReferralLink()) {
      formGroup.controls['referralCode'].setValue(this.userService.getReferralCode());
      formGroup.controls['referralCode'].disable();
    }

    return formGroup;
  }

  setSelectedCountry() {
    switch (this.nationalityType) {
      case NationalityType.MALAYSIA:
        this.registerForm.controls['nationality'].setValue(PopularCountries[0].name); // Malaysia
        break;
      case NationalityType.OTHERS:
        this.registerForm.controls['nationality'].setValue(this.selectedCountry?.name);
        break;
      default:
        break;
    }
  }

  onBackClick() {
    switch (this.progress) {
      case SignUpProgess.SIGNUP_PERSONAL_INFO:
        this.router.navigate(['..'], { relativeTo: this.route });
        break;
      case SignUpProgess.SIGNUP_IDENTIFICATION_INFO:
        this.progress = SignUpProgess.SIGNUP_PERSONAL_INFO;
        break;
      case SignUpProgess.SIGNUP_REFERRAL:
        this.progress = SignUpProgess.SIGNUP_IDENTIFICATION_INFO;
        break;
      default:
        break;
    }
  }

  continue() {
    switch (this.progress) {
      case SignUpProgess.SIGNUP_PERSONAL_INFO:
        this.progress = SignUpProgess.SIGNUP_IDENTIFICATION_INFO;
        break;
      case SignUpProgess.SIGNUP_IDENTIFICATION_INFO:
        this.progress = SignUpProgess.SIGNUP_REFERRAL;
        break;
      case SignUpProgess.SIGNUP_REFERRAL:

        break;
      default:
        break;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  setIdType(type: string) {
    this.info.idType = type;

    this.registerForm.controls['idNumber'].clearValidators();
    this.registerForm.controls['idNumber'].setValidators([
      Validators.required,
      validateFormWhitespaceOnly(),
      validateFormIdentificationNumber(this.info.idType)
    ]);
    this.registerForm.controls['idNumber'].updateValueAndValidity();

    // reset id number
    this.registerForm.controls['idNumber'].setValue('');

    // clear user uploaded image
    this.registerForm.controls['mykadFront'].setValue('');
    this.registerForm.controls['mykadBack'].setValue('');
    this.registerForm.controls['profilePicture'].setValue('');
    this.info.mykadFront = '';
    this.info.mykadBack = '';
    this.info.passport = '';
    this.info.profilePicture = '';

    this.disableContinueIdentificationInfo();
  }

  // add - if id type = MyKad
  setIdNumber(event: KeyboardEvent) {
    if (this.registerForm.controls['idType'].value === IdType.MYKAD) {
      let input: string = this.registerForm.controls['idNumber'].value.trim();

      // add first -
      if (input.length === 6 && event.key !== 'Backspace') {
        this.registerForm.controls['idNumber'].setValue(input + '-');
      }

      // remove first -
      if (input.length === 7 && event.key === 'Backspace') {
        this.registerForm.controls['idNumber'].setValue(input.slice(0, 6));
      }

      // add second -
      if (input.length === 9 && event.key !== 'Backspace') {
        this.registerForm.controls['idNumber'].setValue(input + '-');
      }

      // remove second -
      if (input.length === 10 && event.key === 'Backspace') {
        this.registerForm.controls['idNumber'].setValue(input.slice(0, 9));
      }
    }
  }

  setUploadType(type: number) {
    this.uploadType = type;
  }

  uploadImage(event: any) {
    let imageFile = event.target.files[0];
    this.uploadImageSub = this.commonService.uploadFile(imageFile).subscribe((res: any) => {
      if (res.code === 200) {
        let url = res.data.url;

        switch (this.uploadType) {
          case this.UPLOAD.MYKAD_FRONT:
            this.info.mykadFront = url;
            break;
          case this.UPLOAD.MYKAD_BACK:
            this.info.mykadBack = url;
            break;
          case this.UPLOAD.PASSPORT:
            this.info.passport = url;
            break;
          case this.UPLOAD.PROFILE_PIC:
            this.info.profilePicture = url;
            break;
          default:
            break;
        }
        
        this.disableContinueIdentificationInfo();
      } else {
        console.log(res.msg);
        alert(res.msg);
      }
    });

    /**
     * Fix same file unable to upload twice in a row
     * 
     * https://stackoverflow.com/questions/26634616/filereader-upload-same-file-again-not-working
     * https://github.com/valor-software/ng2-file-upload/issues/220
     */
    this.imageUploadRef.nativeElement.value = '';
  }

  disableContinuePersonalInfo() {
    return this.registerForm.invalid;
  }

  disableContinueIdentificationInfo() {
    switch (this.registerForm.controls['idType'].value) {
      case IdType.MYKAD:  
        this.registerForm.controls['mykadFront'].setValue(this.info.mykadFront);
        this.registerForm.controls['mykadBack'].setValue(this.info.mykadBack);
        this.registerForm.controls['profilePicture'].setValue(this.info.profilePicture);

        this.disableContinueIdInfo = !(
          this.registerForm.controls['mykadFront'].value.trim() !== '' ||
          this.registerForm.controls['mykadBack'].value.trim() !== '' ||
          this.registerForm.controls['profilePicture'].value.trim() !== '');
        break;
      case IdType.PASSPORT:
        // passport set to mykadFront field
        this.registerForm.controls['mykadFront'].setValue(this.info.passport);
        this.registerForm.controls['profilePicture'].setValue(this.info.profilePicture);

        this.disableContinueIdInfo = !(
          this.registerForm.controls['mykadFront'].value.trim() !== '' ||
          this.registerForm.controls['profilePicture'].value.trim() !== '');
        break;
      default:
        break;
    }
  }

  getReferralCodeText(text: string) {
    if (this.userService.getIsReferralLink()) {
      return this.translateService.instant(text);
    } else {
      return this.translateService.instant(text) + ' (' + this.translateService.instant('MyQM.Optional').toLowerCase() + ')';
    }
  }

  register() {
    if (this.registerForm.invalid) {
      alert('invalid');
      console.log('invalid');
    } else {
      let formValue = this.registerForm.getRawValue();

      let idNumber = formValue.idNumber;
      if (formValue.idType === IdType.MYKAD) {
        idNumber = idNumber.replaceAll('-', '');
      }

      let registrationInfo = new RegistrationInfo(formValue.phoneCode, formValue.phone, 
                                                  formValue.code, formValue.fullName, 
                                                  formValue.gender, formValue.email, 
                                                  formValue.nationality, formValue.idType,
                                                  idNumber, formValue.mykadFront,
                                                  formValue.mykadBack, formValue.profilePicture,
                                                  formValue.referralCode);

      this.registerSub = this.userService.register(registrationInfo).subscribe((res: any) => {
        if (res.code === 200) {
          if (this.userService.getIsReferralLink()) {
            this.userService.redirect('register-complete');
          } else {
            location.replace(this.userService.getCallbackUrlWithToken(res.data.token));
          }
        } else {
          console.log(res.msg);
          alert(res.msg);
        }
      });
    }
  }

  ngOnDestroy() {
    this.uploadImageSub?.unsubscribe();
    this.registerSub?.unsubscribe();
  }
}
