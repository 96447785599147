import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-signup-phone-registered',
  templateUrl: './user-signup-phone-registered.component.html',
  styleUrls: ['./user-signup-phone-registered.component.scss']
})
export class UserSignupPhoneRegisteredComponent {

  constructor(private router: Router, private route: ActivatedRoute) {

  }

  login() {
    this.router.navigate(['../login'], {
      relativeTo: this.route
    });
  }
}
